@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Clash Display', sans-serif;
  overflow-x: hidden;
}

#root{
  overflow-x: hidden;
}

.mainCont{
  max-width: 1100px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}